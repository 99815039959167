import { ROUTE_LIST_NAMES } from '@/config/router/appRoutes';
import { defineComponent, onMounted } from '@vue/composition-api';
import { useMeta } from '@/shared/composables/useMeta';
export default defineComponent({
    name: 'ComingSoon',
    setup() {
        const back = async () => {
            globalThis.$router.back();
        };
        onMounted(() => {
            // Send event
            globalThis.$gtm.trackEvent({
                event: 'coming_soon',
                category: 'errors',
                action: 'coming_soon',
                label: 'error coming_soon',
                value: document.location.href,
                noninteraction: true
            });
            useMeta({
                noIndex: true
            });
        });
        return {
            ROUTE_LIST_NAMES,
            back
        };
    }
});
